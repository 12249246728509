import React, { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { fetchProducts } from '../services/stripeService';
import '../styles/ShopPage.css';
const loadingImage = require("../assets/wabbit.png"); // Import the spinning cog image

const stripePromise = loadStripe('pk_test_51QaSQTB4GOcmIwImyI4nnr2VAFAHu0Y1F3dFCVVWs6o2IhOXxu4KASDZLHGcmikqHpoIGqUfNAx4fjWnOQmKaezu00e4lZRpow');
// const stripePromise = loadStripe(`pk_live_51QaSQTB4GOcmIwIm2fB4u4ypUk3eG3q5IqxzUubT2yvaVmYzGj2kbBJWxh5sYBSYY5ohnMjbtKQZ0X4UTJCu3SnG00rfIfIBxv`);

const funFacts = [
  "Did you know? Zombie Mini Arena has hosted over 10,000,000 players!",
  "Fun fact: The known kill record is 35,000!",
  "Zombie Mini Arena has been around for over 4 years!",
  "Did you know? Zombie Mini Arena has an ACCOUNT system!",
];

const ShopPage = () => {
  const [subscriptions, setSubscriptions] = useState([]);
  const [products, setProducts] = useState([]);
  const [subscription, setSubscription] = useState(null);
  const [loading, setLoading] = useState(true); // Unified loading state
  const [error, setError] = useState(null);
  const [user, setUser] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const [authInProgress, setAuthInProgress] = useState(false);
  const [funFactIndex, setFunFactIndex] = useState(0);

  useEffect(() => {
    const authenticateAndFetchData = async () => {
      let token = localStorage.getItem('authToken');
  
      // Extract token from URL if present
      const urlParams = new URLSearchParams(window.location.search);
      const tokenFromUrl = urlParams.get('token');
  
      if (tokenFromUrl) {
        // Store the token in localStorage
        localStorage.setItem('authToken', tokenFromUrl);
        token = tokenFromUrl;
  
        // Clean up the URL to remove the token
        window.history.replaceState({}, document.title, window.location.pathname);
      }
  
      // If no token exists, redirect to Discord login
      if (!token) {
        redirectToDiscord();
        return;
      }
  
      setLoading(true);
  
      try {
        // Validate the token with the backend
        const authResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/auth/user`, {
          headers: { Authorization: `Bearer ${token}` },
        });
  
        if (authResponse.ok) {
          const userData = await authResponse.json();
          setUser(userData);
  
          // Fetch additional user details
          const userDetailsResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/user/${userData.userId}`, {
            headers: { Authorization: `Bearer ${token}` },
          });
  
          if (userDetailsResponse.ok) {
            setUserDetails(await userDetailsResponse.json());
          }
  
          // Fetch subscription details
          const subscriptionResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/subscription/${userData.userId}`, {
            headers: { Authorization: `Bearer ${token}` },
          });
  
          if (subscriptionResponse.ok) {
            setSubscription(await subscriptionResponse.json());
          }
        } else {
          console.warn('User is not authenticated. Redirecting...');
          redirectToDiscord();
        }
  
        // Fetch products
        const fetchedProducts = await fetchProducts();
        setSubscriptions(fetchedProducts.filter((product) => product.type === 'subscription'));
        setProducts(fetchedProducts.filter((product) => product.type === 'product'));
      } catch (err) {
        console.error('Error fetching data:', err);
        setError('Failed to load data. Please try again later.');
      } finally {
        setLoading(false);
      }
    };
  
    authenticateAndFetchData();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setFunFactIndex((prevIndex) => (prevIndex + 1) % funFacts.length);
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  const redirectToDiscord = () => {
    if (authInProgress) return;
  
    const currentUrl = window.location.href;
    const authUrl = `${process.env.REACT_APP_BACKEND_URL}/auth/discord?redirect=${encodeURIComponent(currentUrl)}`;
    window.location.href = authUrl;
  };

  const handleBuyNow = async (product) => {
    if (!user || !userDetails) {
      redirectToDiscord();
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/create-checkout-session`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          product,
          user: {
            userId: user.userId,
            username: userDetails.username,
            avatar: userDetails.avatar,
          },
        }),
        // credentials: 'include',
      });

      const session = await response.json();
      if (session.url) {
        window.location.href = session.url;
      } else {
        throw new Error('Failed to create checkout session.');
      }
    } catch (error) {
      console.error('Error creating checkout session:', error);
    }
  };

  const renderProduct = (product) => (
    <div
      key={product.id}
      className={`feature-box ${product.featured ? 'featured' : ''} ${product.sale ? 'sale-box sale' : ''}`}
    >
      <img
        src={product.icon || '/default-placeholder.png'}
        alt={product.name}
        className="product-icon"
      />
      <h3 className="feature-title">{product.name}</h3>
      <p className="feature-description">{product.description}</p>
      <button
        className="feature-button"
        onClick={() => handleBuyNow(product)}
        disabled={user && product.type === 'subscription' && subscription && subscription.status === 'active'} // Disable only if user is authenticated and already subscribed
        style={
          user && product.type === 'subscription' && subscription && subscription.status === 'active'
            ? { backgroundColor: '#ddd', color: '#aaa', cursor: 'not-allowed' }
            : {}
        }
      >
        {user && product.type === 'subscription' && subscription && subscription.status === 'active'
          ? `Subscribed to ${
              subscription.tier === 0
                ? 'Wabbit Pro'
                : subscription.tier === 1
                ? 'Wabbit Elite'
                : subscription.tier === 2
                ? 'Wabbit Ultimate'
                : 'Unknown Tier'
            }`
          : `Buy Now • $${(product.price / 100).toFixed(2)}`}
      </button>
    </div>
  );

  if (loading) {
    return (
      <div className="loading-container">
        <img src={loadingImage} alt="Loading products..." className="loading-cog" />
        <p className="loading-text">{funFacts[funFactIndex]}</p>
      </div>
    );
  }

  if (error) {
    return <p className="error-message">{error}</p>;
  }

  return (
    <div className="shop-container">
      <div className="login-box">
        {userDetails ? (
          <div className="user-details">
            <img
              src={userDetails.avatar || `https://cdn.discordapp.com/embed/avatars/1.png`}
              alt="Profile"
              className="user-avatar"
            />
            <p className="user-info">Logged in as {userDetails.username}</p>
          </div>
        ) : (
          <button className="login-button" onClick={redirectToDiscord}>
            Login with Discord
          </button>
        )}
      </div>

      <div className="shop-sections">
        <div className="subscriptions-section">
          <h2 className="category-title">Subscriptions</h2>
          <div className="product-grid">{subscriptions.map(renderProduct)}</div>
        </div>

        <hr className="divider" />

        <div className="products-section">
          <h2 className="category-title">Products</h2>
          <div className="product-grid">{products.map(renderProduct)}</div>
        </div>
      </div>
    </div>
  );
};

export default ShopPage;