import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../styles/ItemsPage.css';
const loadingImage = require("../assets/wabbit.png");

const ItemsPage = () => {
  const [items, setItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('Gear');
  const [selectedItem, setSelectedItem] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [funFactIndex, setFunFactIndex] = useState(0);

  const categories = ['Gear', 'Utility', 'Collectable', 'Chest', 'Mob', 'Misc'];

  const funFacts = [
    "Did you know? Zombie Mini Arena has hosted over 10,000,000 players!",
    "Fun fact: The known kill record is 35,000!",
    "Zombie Mini Arena has been around for over 4 years!",
    "Did you know? Zombie Mini Arena has an ACCOUNT system!",
  ];

  useEffect(() => {
    const fetchItems = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/items`);
        setItems(response.data);
        // Default to 'Gear' category, sorted by price high-to-low
        setFilteredItems(
          response.data
            .filter(item => item.type?.toLowerCase() === 'gear')
            .sort((a, b) => b.price - a.price) // Sort by price descending
        );
      } catch (err) {
        console.error('Error fetching items:', err);
        setError('Failed to fetch items');
      } finally {
        setLoading(false);
      }
    };
  
    fetchItems();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setFunFactIndex((prevIndex) => (prevIndex + 1) % funFacts.length);
    }, 5000);

    return () => clearInterval(interval);
  }, [funFacts]);

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
  
    const filtered = items
      .filter(item => {
        if (category === 'Misc') {
          // Exclude items that belong to other categories
          return !categories.slice(0, -1).some(cat => cat.toLowerCase() === item.type?.toLowerCase());
        }
        // Match item type to the selected category
        return item.type?.toLowerCase() === category.toLowerCase();
      })
      .sort((a, b) => b.price - a.price); // Sort by price descending
  
    setFilteredItems(filtered);
    setSelectedItem(null);
  };

  if (loading) {
    return (
      <div className="loading-container">
        <img src={loadingImage} alt="Loading items..." className="loading-cog" />
        <p className="loading-text">{funFacts[funFactIndex]}</p>
      </div>
    );
  }

  if (error) return <p className="error-message">{error}</p>;

  return (
    <div className="items-page-container">
      <h1 className="page-title">Economy Items</h1>
      <div className="categories-container">
        {categories.map(category => (
          <button
            key={category}
            className={`category-button ${selectedCategory === category ? 'active' : ''}`}
            onClick={() => handleCategoryChange(category)}
          >
            {category}
          </button>
        ))}
      </div>
      <div className="content-container">
        <div className="items-list">
          {filteredItems.length === 0 ? (
            <p className="no-items-message">No items found in this category.</p>
          ) : (
            filteredItems.map(item => (
                <div
  key={item.name}
  className="item-box"
  onClick={() => setSelectedItem(item)}
>
  {/* Item Image */}
  <img src={item.icon} alt={item.name} className="item-icon" />

  {/* Item Content */}
  <div className="item-content">
    <h3 className="item-name">{item.name}</h3>
    <p className="item-description">{item.description}</p>
    <p className="item-price">{item.price.toLocaleString()} WabbitCash</p>
  </div>
</div>
            ))
          )}
        </div>
        {selectedItem && (
          <div className="item-details">
            <h3 className="details-title">{selectedItem.name}</h3>
            <img src={selectedItem.icon} alt={selectedItem.name} className="details-icon" />
            <p className="details-description">{selectedItem.description}</p>
            <p className="details-price">
              <strong>Price:</strong> {selectedItem.price.toLocaleString()} WabbitCash
            </p>
            <p className="details-price">
            <strong>Buyable?</strong> {selectedItem.inShop && selectedItem.price && selectedItem.price !== 0 ? `Yes` : `No`}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ItemsPage;