import React from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css'; // Custom styles for additional polish

const Navbar = () => {
  return (
    <nav className="navbar">
      <Link to="/" className="flex items-center space-x-2">
        <img
          src="https://cdn.discordapp.com/emojis/1271632205508116542.webp?size=64&quality=lossless"
          alt="Zombie Mini Arena Icon"
          className="w-10 h-10"
        />
        <h1 className="navbar-title">Zombie Mini Arena</h1>
      </Link>
      <ul className="nav-links">
        <li><Link to="/profile">Dashboard</Link></li>
        {/* <li><Link to="/">Home</Link></li> */}
        <li><Link to="/commands">Commands</Link></li>
        <li><Link to="/items">Items</Link></li>
        {/* <li><Link to="/accounts">Accounts</Link></li> */}
        <li><Link to="/store">Store</Link></li>
        <li><a href="https://discord.gg/zma" target="_blank" rel="noopener noreferrer">Discord</a></li>
      </ul>
    </nav>
  );
};

export default Navbar;