import React from 'react';
import '../styles/ManagementPage.css';

const ManagementPage = () => {
  // Sample data for management team members
  const managementTeam = [
    { name: 'Cryptic', tag: '@c.r.y.p.t.i.c | 2020', bio: 'Director & Developer' },
    { name: 'Jay', tag: '@dr.jjj | 2020', bio: 'Director & Token User' },
    { name: 'Swirl', tag: '@swirl127 | 2022', bio: 'Director & Token User' },
    { name: 'Alm', tag: '@almightygzz | 2021', bio: 'Executive Manager & Token User' },
    { name: 'Fire', tag: '@fire7676 | 2021', bio: 'Executive Manager' },
    { name: 'Wewster', tag: '@theewewster | 2022', bio: 'Community Manager' },
    { name: 'Vig', tag: '@giv205 | 2022', bio: 'Server Manager' },
    { name: 'Nampy', tag: '@takeanampy | 2022', bio: 'Server Manager' },
    { name: 'Logixx', tag: '@logix_x | 2024', bio: 'Server Manager' },
    { name: 'Ember', tag: '@ember135890 | 2024', bio: 'Assistant Server Manager' },
  ];

  return (
    <div className="management-page-container">
      <h1 className="page-title">Management Team</h1>
      <div className="management-grid">
        {managementTeam.map((member, index) => (
          <div key={index} className="management-box">
            <h3 className="management-name">{member.name}</h3>
            <p className="management-tag">{member.tag}</p>
            <p className="management-bio">{member.bio}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ManagementPage;