import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useParams, Outlet, useNavigate } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Sidebar from './components/Sidebar';
import HomePage from './pages/HomePage';
import CommandsPage from './pages/CommandsPage';
import ItemsPage from './pages/ItemsPage';
import ManagementPage from './pages/ManagementPage';
import TermsOfServicePage from './pages/TermsOfServicePage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import Account from './pages/Account';
import Subscription from './pages/Subscription';
import Store from './pages/ShopPage';
import SuccessPage from './pages/SuccessPage';
import Inventory from './pages/Inventory';
import WelcomePage from './pages/WelcomePage';
import Settings from './pages/Settings';
import './styles/App.css';
import './styles/404Page.css';

// Check if backend URL is properly configured
if (!process.env.REACT_APP_BACKEND_URL) {
  console.warn('REACT_APP_BACKEND_URL is not defined. Please set it in your .env file.');
}

// Redirect Component
const ExternalRedirect = ({ url }) => {
  useEffect(() => {
    window.location.href = url;
  }, [url]);
  return null; // Render nothing while redirecting
};

// Authentication and Profile Redirection
const ProfileAuth = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const authenticateUser = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const tokenFromUrl = urlParams.get('token');
      const redirectUrl = urlParams.get('redirect') || window.location.href;

      // Save the token from the URL to localStorage
      if (tokenFromUrl) {
        localStorage.setItem('authToken', tokenFromUrl);

        // Clean up the URL
        window.history.replaceState({}, document.title, window.location.pathname);
      }

      const token = localStorage.getItem('authToken');
      if (!token) {
        console.warn('No token found. Redirecting to Discord login...');
        window.location.href = `${process.env.REACT_APP_BACKEND_URL}/auth/discord?redirect=${encodeURIComponent(redirectUrl)}`;
        return;
      }

      try {
        // Validate the token with the backend
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/auth/user`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        if (response.ok) {
          const userData = await response.json();

          // Redirect only if the user is not already on the correct route
          const currentPath = window.location.pathname;
          const intendedPath = `/profile/${userData.userId}`;
          if (!currentPath.startsWith(intendedPath)) {
            navigate(intendedPath + window.location.search); // Preserve query parameters
          }
        } else {
          console.error('Authentication failed. Redirecting...');
          window.location.href = `${process.env.REACT_APP_BACKEND_URL}/auth/discord?redirect=${encodeURIComponent(redirectUrl)}`;
        }
      } catch (error) {
        console.error('Error during authentication:', error);
        window.location.href = `${process.env.REACT_APP_BACKEND_URL}/auth/discord?redirect=${encodeURIComponent(redirectUrl)}`;
      }
    };

    authenticateUser();
  }, [navigate]);

  return <p>Authenticating...</p>;
};

const ProfileLayout = () => {
  const { userId } = useParams();

  return (
    <div className="profile-layout">
      {/* Sidebar */}
      <Sidebar userId={userId} />

      {/* Main Content */}
      <div className="profile-content">
        <Outlet />
      </div>
    </div>
  );
};

const NotFound = () => (
  <div className="page-not-found-container">
    <h1 className="not-found-title">404 - Page Not Found</h1>
    <p className="not-found-subtitle">
      Oops! The page you're looking for doesn't exist or has been moved.
    </p>
    <a href="/" className="go-back-button">Go Back to Home</a>
  </div>
);

function App() {
  return (
    <Router>
      <div className="app-container">
        <Navbar />
        <main className="main-content">
          <Routes>
            {/* General Routes */}
            <Route path="/" element={<HomePage />} />
            <Route path="/commands" element={<CommandsPage />} />
            <Route path="/items" element={<ItemsPage />} />
            <Route path="/management" element={<ManagementPage />} />
            <Route path="/terms-of-service" element={<TermsOfServicePage />} />
            <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
            <Route path="/accounts" element={<Account />} />
            <Route path="/shop" element={<Store />} />
            <Route path="/store" element={<Store />} />
            <Route path="/success" element={<SuccessPage />} />
            <Route path="/pod" element={<ExternalRedirect url="https://podwebsite.netlify.app/shop" />} />
            <Route path="/discord-store" element={<ExternalRedirect url="https://discord.com/channels/735604263836975145/1317839828917686303" />} />
            <Route path="/discord" element={<ExternalRedirect url="https://discord.gg/zma" />} />

            {/* Profile Routes */}
            <Route path="/profile" element={<ProfileAuth />} /> {/* Authentication handler */}
            <Route path="/profile/:userId" element={<ProfileLayout />}>
              <Route index element={<WelcomePage />} /> {/* Default Welcome Page */}
              <Route path="inventory" element={<Inventory />} />
              <Route path="settings" element={<Settings />} />
              <Route path="subscription" element={<Subscription />} />
              {/* Add more nested routes as needed */}
            </Route>

            {/* 404 Fallback */}
            <Route path="*" element={<NotFound />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;