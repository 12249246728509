import React from "react";
import "../styles/HomePage.css"; // Reusing the existing HomePage styles

const Account = () => {
  return (
    <div className="homepage-container">
      {/* Hero Section */}
      <div className="hero-title-container">
        <h1 className="hero-title">Zombie Mini Arena Account System</h1>
        <p className="hero-subtitle">Save progress, recover items, track stats, and more @ discord.gg/zma</p>
      </div>

      {/* Containers Section */}
      <div className="features-section">
        {/* Container 1 */}
        <div className="feature-box">
          <h3 className="feature-title">Inventory Saving</h3>
          <ul className="feature-description-list">
            <li>Save your in-game inventory when you have to leave</li>
            <li>Never worry about dying in AFK again</li>
            <li>Recover your in-game items whenever you want</li>
          </ul>
        </div>

        {/* Container 2 */}
        <div className="feature-box">
          <h3 className="feature-title">Stat Tracking</h3>
          <ul className="feature-description-list">
            <li>Track your lifetime Zombie Mini Arena stats</li>
            <li>Unlock in-game achievements and badges</li>
            <li>Earn Discord roles and perks automatically</li>
          </ul>
        </div>

        {/* Container 3 */}
        <div className="feature-box">
          <h3 className="feature-title">Automatic Refunds</h3>
          <ul className="feature-description-list">
            <li>Enjoy automatic refunds after almost every reset</li>
            <li>Managers can refund your items with one button click</li>
            <li>The server is backed up at all times to prevent data loss</li>
          </ul>
        </div>

        {/* Container 4 */}
        <div className="feature-box">
          <h3 className="feature-title">In-Game Trading</h3>
          <ul className="feature-description-list">
            <li>You no longer need to open a ticket to trade in-game items</li>
            <li>All trading is now done through WabbitBot</li>
            <li>In-game items can be traded for Garland or WabbitCash</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Account;