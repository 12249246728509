import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import '../styles/WelcomePage.css';

const WelcomePage = () => {
  const { state } = useLocation();
  const { userId } = useParams();
  const navigate = useNavigate(); // To handle navigation
  const [userData, setUserData] = useState(null);
  const hasFetched = useRef(false); // Track if fetch has already been done

  useEffect(() => {
    const fetchUserData = async () => {
      const token = localStorage.getItem('authToken');
      const currentUrl = window.location.href;

      if (!token) {
        console.error('No token found. User must reauthenticate.');
        window.location.href = `${process.env.REACT_APP_BACKEND_URL}/auth/discord?redirect=${encodeURIComponent(currentUrl)}`;
        return;
      }

      try {
        // Fetch user data using the /user/:userId endpoint
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/user/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          const avatarUrl =
          data.avatar && !data.avatar.startsWith('http')
            ? `https://cdn.discordapp.com/avatars/${data.id}/${data.avatar}.png`
            : data.avatar || `https://cdn.discordapp.com/embed/avatars/${parseInt(data.discriminator, 10) % 5}.png`;

            console.log(`Avatar URL: ${avatarUrl}`);

          setUserData({
            username: data.username,
            avatar: avatarUrl,
          });
        } else {
          console.error('Failed to fetch user data. Redirecting to Discord login.');
          window.location.href = `${process.env.REACT_APP_BACKEND_URL}/auth/discord?redirect=${encodeURIComponent(currentUrl)}`;
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
        window.location.href = `${process.env.REACT_APP_BACKEND_URL}/auth/discord?redirect=${encodeURIComponent(currentUrl)}`;
      }
    };

    // Ensure the fetch only happens once
    if (!hasFetched.current) {
      hasFetched.current = true;
      fetchUserData();
    }
  }, [userId]);

  if (!userData) {
    return (
      <div className="welcome-container">
        <div className="loading-message">Loading user data...</div>
      </div>
    );
  }

  return (
    <div className="welcome-container">
      <div className="welcome-content">
        <div className="welcome-user-info">
          <img
            src={userData.avatar}
            alt={`${userData.username || 'User'}'s Avatar`}
            className="welcome-avatar-inline"
          />
          <h2 className="welcome-title-inline">
            Welcome, {userData.username || 'Guest'}!
          </h2>
        </div>
        <p className="welcome-message">
          Explore your inventory, settings, and more in your personalized dashboard.
        </p>
        <button
          className="shop-button"
          onClick={() => navigate('/shop')} // Navigate to shop page
        >
          Go To Shop
        </button>
      </div>
    </div>
  );
};

export default WelcomePage;