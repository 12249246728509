import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/Footer.css';

const Footer = () => {
  const links = [
    { path: "/management", label: "Management" },
    { path: "/terms-of-service", label: "Terms of Service" },
    { path: "/privacy-policy", label: "Privacy Policy" },
  ];

  return (
    <footer className="footer">
      <ul className="footer-links">
        {links.map((link, index) => (
          <React.Fragment key={link.label}>
            <li className="footer-link-item">
              <Link to={link.path}>{link.label}</Link>
            </li>
            {index < links.length - 1 && <span className="footer-separator">・</span>}
          </React.Fragment>
        ))}
      </ul>
    </footer>
  );
};

export default Footer;