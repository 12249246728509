import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../styles/CommandsPage.css';
const loadingImage = require("../assets/wabbit.png");

const CommandsPage = () => {
  const [commands, setCommands] = useState([]);
  const [filteredCommands, setFilteredCommands] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('Economy');
  const [selectedCommand, setSelectedCommand] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [funFactIndex, setFunFactIndex] = useState(0);

  const categories = ['Economy', 'Utility', 'Moderation', 'Virtual Token', 'Account'];

  const funFacts = [
    "Did you know? Zombie Mini Arena has hosted over 10,000,000 players!",
    "Fun fact: The known kill record is 35,000!",
    "Zombie Mini Arena has been around for over 4 years!",
    "Did you know? Zombie Mini Arena has an ACCOUNT system!",
  ];

  useEffect(() => {
    const fetchCommands = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/commands`);
        setCommands(response.data);
        setFilteredCommands(
          response.data.filter(command => command.type?.toLowerCase() === 'economy')
        );
      } catch (err) {
        console.error('Error fetching commands:', err);
        setError('Failed to fetch commands');
      } finally {
        setLoading(false);
      }
    };
    fetchCommands();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setFunFactIndex((prevIndex) => (prevIndex + 1) % funFacts.length);
    }, 5000);

    return () => clearInterval(interval);
  }, [funFacts]);

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
    setFilteredCommands(commands.filter(command => command.type?.toLowerCase() === category.toLowerCase()));
    setSelectedCommand(null);
  };

  const formatCooldown = (ms) => {
    if (ms >= 604800000) {
      const weeks = Math.floor(ms / 604800000);
      const days = Math.floor((ms % 604800000) / 86400000);
      return `${weeks}w ${days > 0 ? `${days}d` : ''}`;
    } else if (ms >= 86400000) {
      const days = Math.floor(ms / 86400000);
      const hours = Math.floor((ms % 86400000) / 3600000);
      return `${days}d ${hours > 0 ? `${hours}h` : ''}`;
    } else if (ms >= 3600000) {
      const hours = Math.floor(ms / 3600000);
      const minutes = Math.floor((ms % 3600000) / 60000);
      return `${hours}h ${minutes > 0 ? `${minutes}m` : ''}`;
    } else if (ms >= 60000) {
      const minutes = Math.floor(ms / 60000);
      const seconds = Math.floor((ms % 60000) / 1000);
      return `${minutes}m ${seconds > 0 ? `${seconds}s` : ''}`;
    } else {
      const seconds = Math.floor(ms / 1000);
      return `${seconds}s`;
    }
  };

  if (loading) {
    return (
      <div className="loading-container">
        <img src={loadingImage} alt="Loading commands..." className="loading-cog" />
        <p className="loading-text">{funFacts[funFactIndex]}</p>
      </div>
    );
  }

  if (error) return <p className="error-message">{error}</p>;

  return (
    <div className="commands-page-container">
      <h1 className="page-title">WabbitBot Commands</h1>
      <div className="categories-container">
        {categories.map(category => (
          <button
            key={category}
            className={`category-button ${selectedCategory === category ? 'active' : ''}`}
            onClick={() => handleCategoryChange(category)}
          >
            {category}
          </button>
        ))}
      </div>
      <div className="content-container">
        <div className="commands-list">
          {filteredCommands.length === 0 ? (
            <p className="no-commands-message">No commands found in this category.</p>
          ) : (
            filteredCommands.map(command => (
              <div
                key={command.name}
                className="command-item"
                onClick={() => setSelectedCommand(command)}
              >
                <h3 className="command-name">/{command.name}</h3>
                <p className="command-description">{command.description}</p>
              </div>
            ))
          )}
        </div>
        {selectedCommand && (
          <div className="command-details">
            <h3 className="details-title">/{selectedCommand.name}</h3>
            <p className="details-description">{selectedCommand.description}</p>
            <p className="details-cooldown">
              <strong>Cooldown:</strong> {formatCooldown(selectedCommand.cooldown)}
            </p>
            <p className="details-cooldown">
              <strong>Premium Cooldown:</strong> {formatCooldown(selectedCommand.premiumCooldown)}
            </p>
            <p className="details-cooldown">
              <strong>Ultimate Cooldown:</strong> {formatCooldown(selectedCommand.ultimateCooldown)}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default CommandsPage;