import React from 'react';
import '../styles/TermsOfService.css';

const TermsOfServicePage = () => {
  return (
    <div className="policy-page-container">
      <h1 className="policy-page-title">Terms of Service</h1>
      <div className="policy-content">
        <p>
          By accessing or using our services, you agree to be bound by these Terms of Service. Please read them carefully.
        </p>

        <h2>1. Use of Services</h2>
        <ul>
          <li>You agree to use our services only for lawful purposes and in accordance with these terms.</li>
          <li>You must not use our services to engage in harassment, spam, or any illegal activity.</li>
          <li>We reserve the right to suspend or terminate your access to our services for any violation of these terms.</li>
        </ul>

        <h2>2. Payments and Refunds</h2>
        <ul>
          <li>Payments for subscriptions or digital products are processed securely through Stripe.</li>
          <li>We do not see or store sensitive payment details like credit card numbers.</li>
          <li>All sales are final. Refunds will only be issued in cases where required by applicable law.</li>
        </ul>

        <h2>3. Service Availability</h2>
        <ul>
          <li>Our services are provided on an "as-is" and "as-available" basis without warranties of any kind.</li>
          <li>We do not guarantee uninterrupted or error-free operation of our services.</li>
          <li>We are not responsible for any downtime, outages, or other issues caused by factors beyond our control, such as Discord or Stripe outages.</li>
        </ul>

        <h2>4. Liability and Disclaimers</h2>
        <ul>
          <li>We are not liable for any loss or damage resulting from the use or inability to use our services.</li>
          <li>You agree to indemnify us against any claims arising from your use of our services.</li>
        </ul>

        <h2>5. Changes to These Terms</h2>
        <p>We may update these terms from time to time. Changes will be posted on this page, and continued use of our services constitutes acceptance of the updated terms.</p>

        <h2>6. Contact Information</h2>
        <p>If you have any questions about these terms, please contact us through the support section on our website.</p>

        <p>By continuing to use our services, you acknowledge that you have read, understood, and agreed to these terms.</p>
      </div>
    </div>
  );
};

export default TermsOfServicePage;