// src/components/Sidebar.js
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types'; // For prop validation
import { NavLink } from 'react-router-dom';
import '../styles/Sidebar.css';

const Sidebar = ({ userId }) => {
  const [userData, setUserData] = useState({ username: '', avatar: '' });

  useEffect(() => {
    const fetchUserData = async () => {
      if (!userId) {
        console.warn('Sidebar rendered without userId.');
        return;
      }
    
      const token = localStorage.getItem('authToken');
      if (!token) {
        console.warn('No token found. Cannot fetch user data.');
        return;
      }
    
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/user/${userId}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
    
        if (response.ok) {
          const data = await response.json();
          setUserData({
            username: data.username,
            avatar: data.avatar || '/default-avatar.png',
          });
        } else if (response.status === 404) {
          console.error('User not found.');
        } else {
          console.error(`Unexpected error: ${response.status}`);
        }
      } catch (error) {
        console.error('Error fetching user data:', error.message);
      }
    };

    fetchUserData();
  }, [userId]);

  return (
    <div className="horizontal-sidebar">
      <div className="sidebar-links">
      <NavLink
          to={`/profile/${userId}/subscription`}
          className={({ isActive }) => (isActive ? 'active-link' : '')}
        >
          Subscription
        </NavLink>
        <NavLink
          to={`/profile/${userId}/inventory`}
          className={({ isActive }) => (isActive ? 'active-link' : '')}
        >
          Inventory
        </NavLink>
        <NavLink
          to={`/store`}
          className={({ isActive }) => (isActive ? 'active-link' : '')}
        >
          Store
        </NavLink>
      </div>
      <div className="sidebar-user-info">
        <img
          src={userData.avatar || '/default-avatar.png'}
          alt={`${userData.username || 'User'}'s Avatar`}
          className="sidebar-avatar"
        />
        <span className="sidebar-username">{userData.username || 'Guest'}</span>
      </div>
    </div>
  );
};

Sidebar.propTypes = {
  userId: PropTypes.string.isRequired,
};

export default Sidebar;