import React from 'react';
import '../styles/PrivacyPolicyPage.css';

const PrivacyPolicyPage = () => {
  return (
    <div className="policy-page-container">
      <h1 className="policy-page-title">Privacy Policy</h1>
      <div className="policy-content">
        <p>
          Your privacy is important to us. This Privacy Policy explains how we collect, use, and protect your information when you use our services.
        </p>

        <h2>1. Information We Collect</h2>
        <ul>
          <li>
            <strong>Account Information:</strong> When you interact with our services, we may collect your Discord account information, such as your username, ID, avatar, and server roles.
          </li>
          <li>
            <strong>Payment Information:</strong> All payment processing is securely managed by Stripe. We do not collect, store, or have access to sensitive card information such as credit card numbers or CVVs. However, we may store transaction IDs and other metadata related to purchases to fulfill your orders.
          </li>
          <li>
            <strong>Usage Data:</strong> We may collect non-personalized data such as the number of times you use certain commands, interaction patterns, or general engagement statistics to improve our services.
          </li>
        </ul>

        <h2>2. How We Use Your Information</h2>
        <ul>
          <li>To provide and improve our services.</li>
          <li>To manage subscriptions, purchases, and rewards.</li>
          <li>To communicate updates, promotions, or critical service-related messages.</li>
          <li>To analyze usage trends and improve user experience.</li>
        </ul>

        <h2>3. How Your Information is Protected</h2>
        <ul>
          <li>
            We employ industry-standard security measures to protect your data, including encryption, secure access protocols, and firewalls.
          </li>
          <li>
            Payment-related data is managed entirely by Stripe, a certified PCI Service Provider Level 1, ensuring your financial data is secure.
          </li>
        </ul>

        <h2>4. Sharing and Disclosure</h2>
        <ul>
          <li>We do not sell or rent your personal information to third parties.</li>
          <li>We may share data with trusted third-party services, such as Discord and Stripe, solely to provide functionality related to our services.</li>
          <li>We may disclose information if required by law or to protect our legal rights.</li>
        </ul>

        <h2>5. Data Retention</h2>
        <p>We retain information only as long as necessary to provide our services or to comply with legal obligations.</p>

        <h2>6. Your Rights</h2>
        <ul>
          <li>You can request access to the personal data we collect about you.</li>
          <li>You can request correction or deletion of your data, subject to our legal obligations.</li>
        </ul>

        <h2>7. Updates to This Policy</h2>
        <p>We may update this Privacy Policy from time to time. Changes will be posted on this page, and we encourage you to review the policy periodically.</p>

        <p>By using our services, you acknowledge and accept this Privacy Policy.</p>
      </div>
    </div>
  );
};

export default PrivacyPolicyPage;