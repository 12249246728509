import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/SuccessPage.css';

const SuccessPage = () => {
  const [sessionData, setSessionData] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const sessionId = params.get('session_id');

    if (sessionId) {
      fetch(`${process.env.REACT_APP_BACKEND_URL}/api/stripe/session/${sessionId}`)
        .then((response) => {
          if (!response.ok) {
            throw new Error(`Failed to fetch session: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          if (!data.username || !data.productName) {
            throw new Error('Invalid session data');
          }
          setSessionData(data);
          setLoading(false);
        })
        .catch((error) => {
          console.error('Error fetching session details:', error);
          navigate('/store'); // Redirect to /store on error
        });
    } else {
      navigate('/store'); // Redirect if no session_id in URL
    }
  }, [navigate]);

  if (loading) {
    return (
      <div className="successpage-container">
        <h2 className="successpage-title">Processing your transaction...</h2>
      </div>
    );
  }

  if (!sessionData) {
    return (
      <div className="successpage-container">
        <h2 className="successpage-title">Thank you for your purchase!</h2>
        <p className="successpage-message">Your transaction was successful.</p>
      </div>
    );
  }

  const { userId, username, avatar, productName, productIcon, type } = sessionData;

  return (
    <div className="successpage-container">
      <div className="successpage-content">
        <h2 className="successpage-title">Thank you for your purchase</h2>
        <div className="successpage-images">
          {avatar && <img src={avatar} alt="User Avatar" className="successpage-avatar" />}
          {productIcon && <img src={productIcon} alt="Product Icon" className="successpage-product-icon" />}
        </div>
        {type === 'subscription' ? (
          <p className="successpage-message">Your {productName} subscription has been activated — enjoy!</p>
        ) : (
          <p className="successpage-message">
            <strong>{productName}</strong> has been added to <strong>{username}'s</strong> inventory
          </p>
        )}
        <div className="successpage-buttons">
          {type === 'subscription' ? (
            <button
              className="successpage-button"
              onClick={() =>
                window.location.href = `${window.location.origin}/profile/${userId}/subscription`
              }
            >
              View Subscription
            </button>
          ) : (
            <button
              className="successpage-button"
              onClick={() =>
                window.location.href = `${window.location.origin}/profile/${userId}/inventory`
              }
            >
              View Inventory
            </button>
          )}
          <button className="successpage-button" onClick={() => navigate('/store')}>Back to Store</button>
        </div>
      </div>
    </div>
  );
};

export default SuccessPage;