import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import '../styles/Inventory.css';

const Inventory = () => {
  const [inventory, setInventory] = useState([]);
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [user, setUser] = useState(null); // User details fetched during authentication
  const location = useLocation();
  const navigate = useNavigate();

  const redirectToDiscord = (product) => {  
    const currentUrl = window.location.href; // Get the current page URL
    const redirectUrl = currentUrl;
  
    window.location.href = `${process.env.REACT_APP_BACKEND_URL}/auth/discord?redirect=${encodeURIComponent(redirectUrl)}`;
  };

  useEffect(() => {
    const checkAuth = async () => {
      const token = localStorage.getItem('authToken');
      if (!token) {
        redirectToDiscord();
        return;
      }
    
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/auth/user`, {
          headers: { Authorization: `Bearer ${token}` },
        });
    
        if (response.ok) {
          const userData = await response.json();
          setUser(userData);
          return userData.userId;
        } else {
          console.warn('User not authenticated, redirecting...');
          setUser(null);
          redirectToDiscord();
        }
      } catch (err) {
        console.error('Error checking authentication:', err);
        redirectToDiscord();
      }
    };

    const fetchInventory = async () => {
      try {
        // Attempt to get userId from location.state or check authentication
        const userId = location.state?.userId || (await checkAuth());
        if (!userId) return;

        // Fetch user's inventory and item details
        const [inventoryRes, itemsRes] = await Promise.all([
          fetch(`${process.env.REACT_APP_BACKEND_URL}/api/inventory/${userId}`),
          fetch(`${process.env.REACT_APP_BACKEND_URL}/api/items`),
        ]);

        if (!inventoryRes.ok || !itemsRes.ok) {
          throw new Error('Failed to fetch inventory or items');
        }

        const inventoryData = await inventoryRes.json();
        const itemsData = await itemsRes.json();

        setInventory(inventoryData.inventory || []);
        setItems(itemsData || []);
      } catch (err) {
        console.error('Error fetching inventory:', err);
        setError('Failed to load inventory. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchInventory();
  }, [navigate, location.state]);

  const renderInventory = () => {
    // Map inventory items to display with item details
    return inventory.map(({ name, amount }) => {
      const itemDetails = items.find((item) => item.name === name);
      if (!itemDetails) return null;

      return (
        <div key={name} className="inventory-item">
          <div className="inventory-icon-container">
            <img
              src={itemDetails.icon || '/default-placeholder.png'}
              alt={itemDetails.name}
              className="inventory-icon"
              title={itemDetails.name} // Tooltip for better accessibility
            />
          </div>
          <div className="inventory-info">
            <h3 className="inventory-name">{itemDetails.name}・{amount}x</h3>
            <p className="inventory-description">{itemDetails.description}</p>
          </div>
        </div>
      );
    });
  };

  return (
    <div className="inventory-container">
      {loading ? (
        <p>Loading your inventory...</p>
      ) : error ? (
        <p className="error-message">{error}</p>
      ) : (
        <div className="inventory-content">
          <h1 className="inventory-title">Your Inventory</h1>
          <div className="inventory-grid">{renderInventory()}</div>
          <button className="feature-button" onClick={() => navigate('/store')}>
            Browse Store
          </button>
        </div>
      )}
    </div>
  );
};

export default Inventory;