export const fetchProducts = async () => {
    try {
      // const response = await fetch('https://wabbit-website-2bb7528e7ef2.herokuapp.com/api/products');
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/products`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const products = await response.json();
      return products;
    } catch (error) {
      console.error('Error fetching products:', error);
      throw error;
    }
  };