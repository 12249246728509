import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import '../styles/HomePage.css';

const HomePage = () => {
  const [playerCounts, setPlayerCounts] = useState({ na: null, as: null, total: null });
  const [stats, setStats] = useState({
    joinsThisYear: null,
    itemsClaimedThisYear: null,
    kitsClaimedThisYear: null,
  });

  // Fetch Player Counts
  useEffect(() => {
    const fetchPlayerCounts = async () => {
      try {
        const response = await fetch('https://starve.io/list');
        const data = await response.json();

        const naServer = data.find((server) => server.n === 'atlanta');
        const naPlayerCount = naServer?.l.find((server) => server[0] === 'community204')?.[3] || 0;

        const asServer = data.find((server) => server.n === 'osaka');
        const asPlayerCount = asServer?.l.find((server) => server[0] === 'community286')?.[3] || 0;

        setPlayerCounts({ na: naPlayerCount, as: asPlayerCount, total: naPlayerCount + asPlayerCount });
      } catch (error) {
        console.error('Failed to fetch player counts:', error);
      }
    };

    fetchPlayerCounts();
  }, []);

  // Fetch Stats Data
  useEffect(() => {
    const fetchStats = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/stats`);
        const data = await response.json();
  
        // Assuming the stats are in the first element of the array
        const statsData = data[0];
        setStats({
          joinsThisYear: statsData.joins || 0,
          itemsClaimedThisYear: statsData.itemClaims || 0,
          kitsClaimedThisYear: statsData.kitClaims || 0,
        });

      } catch (error) {
        console.error('Failed to fetch stats:', error);
      }
    };
  
    fetchStats();
  }, []);

  return (
    <div className="homepage-container">
  {/* Player Count Box (Top-left) */}
  <div className="player-stats">
    <h3 className="player-box-title">Players Online</h3>
    <p>NA (Atlanta): {playerCounts.na !== null ? playerCounts.na : 'Loading...'}</p>
    <p>Asia (Osaka): {playerCounts.as !== null ? playerCounts.as : 'Loading...'}</p>
    <p>Total Players: {playerCounts.total !== null ? playerCounts.total : 'Loading...'}</p>
  </div>

  {/* Player Stats Box (Top-right) */}
  <div className="player-count">
    <div className="player-box">
      <h3 className="player-box-title">Stats This Year</h3>
      <p>Players Hosted: {stats.joinsThisYear !== null ? stats.joinsThisYear.toLocaleString() : 'Loading...'}</p>
      <p>Items Claimed: {stats.itemsClaimedThisYear !== null ? stats.itemsClaimedThisYear.toLocaleString() : 'Loading...'}</p>
      <p>Kits Claimed: {stats.kitsClaimedThisYear !== null ? stats.kitsClaimedThisYear.toLocaleString() : 'Loading...'}</p>
    </div>
  </div>

  {/* Hero Section */}
  <div className="hero-title-container">
    <h1 className="hero-title">Welcome to Zombie Mini Arena</h1>
    <p className="hero-subtitle">Explore our global economy, playerbase, and features!</p>
  </div>

      {/* Features Section */}
      <div className="features-section">
        {[
          {
            title: 'Play Zombie Mini Arena',
            description: 'Collaborate, grow, and fight with players from all around the world!',
            image: 'https://cdn.discordapp.com/emojis/1271632205508116542.webp?size=64&quality=lossless',
            links: [
              { href: 'https://starve.io/?server=community204&region=atlanta', text: 'Play North America' },
              { href: 'https://starve.io/?server=community286&region=osaka', text: 'Play Asia' },
            ],
          },
          {
            title: 'Join Our Discord Community',
            description: 'Connect with other players, join exclusive events and giveaways, and take your game to the next level!',
            image: 'https://cdn.discordapp.com/emojis/1282146220386680894.webp?size=96&quality=lossless',
            links: [{ href: 'https://discord.gg/zma', text: 'Join ZMA Discord' }],
          },
          {
            title: 'View Economy Info',
            description: 'View all available gear, utilities, collectables, and more!',
            image: 'https://cdn.discordapp.com/emojis/1271627348403294228.webp?size=64&quality=lossless',
            links: [{ to: '/items', text: 'Explore Items' }],
          },
          {
            title: 'Explore the ZMA Shop',
            description: 'Browse subscriptions, WabbitCash, and in-game gear for sale!',
            image: 'https://cdn.discordapp.com/emojis/1271622641794678824.webp?size=64&quality=lossless',
            links: [{ to: '/store', text: 'Explore Items' }],
          },
        ].map((feature, index) => (
          <div key={index} className="feature-box">
            <img src={feature.image} alt={feature.title} className="feature-image" />
            <h3 className="feature-title">{feature.title}</h3>
            <p className="feature-description">{feature.description}</p>
            <div className="feature-links">
              {feature.links.map((link, idx) =>
                link.href ? (
                  <a
                    key={idx}
                    href={link.href}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="feature-button"
                  >
                    {link.text}
                  </a>
                ) : (
                  <Link key={idx} to={link.to} className="feature-button">
                    {link.text}
                  </Link>
                )
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HomePage;