import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import '../styles/Subscription.css';

const Subscription = () => {
  const [subscription, setSubscription] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [user, setUser] = useState(null); // User details fetched during authentication
  const location = useLocation();
  const navigate = useNavigate();

  const STRIPE_MANAGE_URL = process.env.REACT_APP_STRIPE_MANAGE_URL || "https://manage.stripe.com"; // Default if env variable isn't set

  const redirectToDiscord = () => {
    const currentUrl = window.location.href;
    const authUrl = `${process.env.REACT_APP_BACKEND_URL}/auth/discord?redirect=${encodeURIComponent(currentUrl)}`;
    window.location.href = authUrl;
  };

  const calculateRenewalDate = (lastPaymentDate) => {
    const date = new Date(lastPaymentDate);
    const nextRenewalDate = new Date(date.getFullYear(), date.getMonth() + 1, date.getDate());

    // Adjust for months with fewer days
    if (nextRenewalDate.getDate() !== date.getDate()) {
      nextRenewalDate.setDate(0); // Set to last day of the previous month
    }

    return nextRenewalDate;
  };

  const tierIcons = {
    2: "https://cdn.discordapp.com/emojis/1323701318807720006.webp?size=240",
    1: "https://cdn.discordapp.com/emojis/1323701345848528997.webp?size=240",
    0: "https://cdn.discordapp.com/emojis/1323701376433258547.webp?size=240",
  };

  const tierNames = {
    2: "Wabbit Ultimate",
    1: "Wabbit Elite",
    0: "Wabbit Pro",
  };

  const statusIcons = {
    active: "https://cdn.discordapp.com/emojis/1271659589879861381.webp?size=64&quality=lossless",
    inactive: "https://cdn.discordapp.com/emojis/1271660634471399424.webp?size=64&quality=lossless",
  };

  const renewalDateIcon = "https://cdn.discordapp.com/emojis/1271626841937154079.webp?size=64&quality=lossless";
  const startDateIcon = "https://cdn.discordapp.com/emojis/1271659574256074762.webp?size=64&quality=lossless";

  useEffect(() => {
    const checkAuth = async () => {
      const token = localStorage.getItem('authToken');
      if (!token) {
        console.warn('No token found. Redirecting to Discord login.');
        redirectToDiscord();
        return null;
      }

      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/auth/user`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.ok) {
          const userData = await response.json();
          setUser(userData);
          return userData.userId; // Return the userId for fetching subscription
        } else {
          console.warn('User not authenticated, redirecting...');
          redirectToDiscord();
          return null;
        }
      } catch (err) {
        console.error('Error checking authentication:', err);
        redirectToDiscord();
        return null;
      }
    };

    const fetchSubscription = async () => {
      const userId = location.state?.userId || (await checkAuth());
      if (!userId) return;

      const token = localStorage.getItem('authToken');
      if (!token) return;

      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/subscription/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setSubscription(data);
        } else if (response.status === 404) {
          setSubscription(null); // No subscription found
        } else {
          throw new Error('Failed to fetch subscription.');
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchSubscription();
  }, [navigate, location.state]);

  if (loading) {
    return <div className="subscription-container">Loading subscription details...</div>;
  }

  if (error) {
    return <div className="subscription-container error-message">{error}</div>;
  }

  if (!subscription) {
    return (
      <div className="subscription-container no-subscription">
        <div className="subscription-title">You do not have an active subscription</div>
        <button
          className="subscription-store-button"
          onClick={() => navigate('/store')}
        >
          Let's Fix That!
        </button>
      </div>
    );
  }

  const nextRenewalDate = calculateRenewalDate(subscription.lastPaymentDate);

  return (
    <div className="subscription-container">
      <h1 className="subscription-title">Your Subscription</h1>
      <h5 className="subscription-subtitle">Please note that subscriptions purchased through Discord will NOT be displayed here</h5>
      <div className="subscription-details">
        <div className="subscription-detail">
          <img src={tierIcons[subscription.tier]} alt="Tier" className="subscription-icon" />
          <p><strong>Tier:</strong> {tierNames[subscription.tier]}</p>
        </div>
        <div className="subscription-detail">
          <img
            src={subscription.status === "active" ? statusIcons.active : statusIcons.inactive}
            alt="Status"
            className="subscription-icon"
          />
          <p><strong>Status:</strong> {subscription.status.replace(/\b\w/g, (char) => char.toUpperCase())}</p>
        </div>
        <div className="subscription-detail">
          <img src={startDateIcon} alt="Start Date" className="subscription-icon" />
          <p><strong>Subscriber Since:</strong> {new Date(subscription.startDate).toLocaleDateString()}</p>
        </div>
        <div className="subscription-detail">
          <img src={renewalDateIcon} alt="Renewal Date" className="subscription-icon" />
          <p><strong>Renewal Date:</strong> {nextRenewalDate.toLocaleDateString()}</p>
        </div>
      </div>
      <button
        className="subscription-manage-button"
        onClick={() => window.location.href = STRIPE_MANAGE_URL}
      >
        Manage Subscription
      </button>
    </div>
  );
};

export default Subscription;